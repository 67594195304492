/* Base CSS */

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "timerFont";
  src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
}
@font-face {
  font-family: "TimerFont2";
  src: url(../fonts/VarelaRound-Regular.ttf);
}

.alignright {
  float: right;
  margin-left: 15px;
}

.aligncenter {
  display: block;
  margin: 0 auto 15px;
}

a:focus {
  outline: 0 solid;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  color: #3e3b6d;
  font-family: "Roboto", sans-serif;
}

body {
  color: #737fc7;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  line-height: 25px;
}
@font-face {
  font-family: "Nexa";
  src: url("../fonts/NexaBold.woff2") format("woff2"),
    url("../fonts/NexaBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

.selector-for-some-widget {
  box-sizing: content-box;
}

a:hover {
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
}

textarea:focus {
  outline: 0;
  outline: 5px auto -webkit-focus-ring-color;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.container {
  max-width: 1370px;
  margin: auto;
}

/* Header area css Start */
.header-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
}

.header-mainmenu ul li {
  display: inline-block;
  padding-left: 33px;
}
.header-site-menu {
  display: flex;
  align-items: center;
  justify-content: end;
  float: right;
}
.right-btn {
  margin-left: 33px;
  margin-top: -14px;
}
.header-mainmenu ul li a {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  display: block;
  padding: 50px 0;
  transition: 0.3s;
  text-decoration: none;
}

.header-mainmenu ul li a:hover {
  color: #bebebe;
  text-decoration: none;
}

.right-btn a {
  background: #fff;
  width: 122px;
  height: 39px;
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  line-height: 39px;
  font-size: 16px;
  color: #0067f4;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  transition: 0.3s;
}

.right-btn a:hover {
  background: #000;
  color: #fff;
  text-decoration: none;
}

/* sticky elements */
.Sticky .header-mainmenu ul li a {
  padding: 17px 0;
  margin-top: 10px;
}

.Sticky .right-btn {
  margin-top: -5px;
}

.Sticky {
  position: fixed;
  background: #0069f3;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 111111;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
}

.Sticky .trans-1 {
  display: none;
}

.Sticky .header-logo {
  margin: 0;
}

/* end sticky elems (navbar) */

.header-logo {
  position: relative;
  margin-top: -14px;
}
.trans-1 {
  position: absolute;
  left: -51px;
  z-index: -1;
}

#scrollUp {
  position: absolute;
  background: #ff0000c4;
  width: 50px;
  height: 50px;
  bottom: 25px;
  right: 28px;
  text-align: center;
  line-height: 50px;
  font-size: 30px;
  color: #fff;
  border-radius: 10px;
}
/* Header area css End */

/* Hero area css Start */
.hero-area.min-height {
  min-height: 576px;
}
.hero-area {
  background-size: cover;
  background-position: center;
}
.hero-wrapper {
  padding-top: 255px;
}
.hero-wrapper h2 {
  font-size: 60px;
  color: #fff;
  font-weight: 500;
  line-height: 70px;
  margin: 0;
  position: relative;
  z-index: 3333;
}
.trans-2 {
  position: absolute;
  top: 160px;
  right: 190px;
}
/* Hero area css End */

/* textarea css Start */
.counter {
  color: #8b8b8b !important;
}

.texarea {
  padding-bottom: 160px;
}
.textarea-block {
  max-width: 752px;
  margin: 0 auto;
  position: relative;
}
.textarea-block textarea {
  outline: none;
  width: 752px;
  height: 218px;
  padding: 20px;
  padding-bottom: 80px !important;
  font-size: 17px;
  color: #000000;
  font-weight: 400;
  border-radius: 8px;
  border: 0;
  box-shadow: 0 10px 10px 8px #dddddd3d;
  background: #fff;
  resize: none;
}
.textarea-link {
  position: absolute;
  bottom: 25px;
  left: 31px;
}
.textarea-block button {
  position: absolute;
  right: 21px;
  bottom: 26px;
  width: 122px;
  height: 39px;
  background: #0692e2;
  border: 0;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  border-radius: 4px;
}
.textarea-link a span {
  background: #e1e8ed;
  width: 35px;
  height: 35px;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}
.text-inner {
  text-align: center;
  padding-top: 35px;
}
.text-inner p {
  font-size: 18px;
  font-weight: 400;
  color: #707070;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin: 0;
}
.text-inner p span {
  color: #0692e2;
}

/* disabled textarea/timer subsection */

.disabled_box {
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.301) !important;
}

.disabled_twtbtn {
  background: rgba(70, 70, 70, 0.342) !important;
}

.textarea-block .timer_pos {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 50px;
  border: 0;
  font-size: 28px;
  font-weight: 400;
  background: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 25px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.timer_icon {
  position: absolute;
  width: 25px;
  height: 25px;
  left: 18px;
  bottom: 14px;
}

.timer {
  position: absolute;
  font-family: "TimerFont2" !important;
  top: 5px;
  right: 24px;
  text-align: center;
  font-size: 26px;

  color: rgb(0, 0, 0);
}

/* chatbox sleep vals below */
.timer_sleep {
  position: absolute;
  font-family: "TimerFont2" !important;
  top: 9px;
  right: 30px;
  text-align: center;
  font-size: 22px;
  color: rgb(0, 0, 0);
}

.textarea-block .timer_pos_sleep {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 50px;
  border: 0;
  font-size: 28px;
  font-weight: 400;
  background: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 25px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.timer_icon_sleep {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 14px;
  bottom: 5px;
}

.text-inner-sleep {
  text-align: center;
  padding-top: -15px !important;
}
.text-inner-sleep p {
  padding-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: #707070;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  margin: 0;
}

.message-info {
  font-size: 12px !important;
}

/* loading textbox classes */
.textarea-block .timer_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 80px;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.loading_box {
  box-shadow: inset 0 0 0 1000px rgba(160, 160, 160, 0.301) !important;
}

/* textarea css End */

/* Recent area css Start */
.recent-area {
  padding-bottom: 130px !important;
}
.card-blk {
  margin-bottom: 30px;
}
.section-title h4 {
  font-size: 20px;
  color: #5b5b5b;
  font-weight: 400;
  margin-bottom: 30px;
}
/* Recent area css End */

/* Footer area css Start */
.foter-area {
  background: #0067f4;
  padding: 30px 0;
}
.footer-text p {
  font-size: 18px;
  color: #fff;
  font-weight: 400;
  margin: 0;
  font-family: "Roboto", sans-serif;
}
.footer-social a {
  font-size: 24px;
  color: #fff;
  margin-left: 16px;
}
/* Footer area css End */

/* About area css Start */
.about-area {
  padding-bottom: 130px !important;
  padding-top: 0px !important;
}
.about-title {
  text-align: center;
  position: relative;
}
.about-title::before {
  position: absolute;
  background: #0067f4;
  width: 70px;
  height: 5px;
  content: "";
  bottom: -6px;
  left: 50%;
  transform: translate(-50%);
  border-radius: 14px;
}
.about-title h2 {
  font-size: 60px;
  color: #000;
  font-weight: 500;
  margin-bottom: 110px;
}

.about-all-content h5 {
  font-size: 22px;
  color: #068ce5;
  font-weight: 500;
  margin-bottom: 14px;
}
.about-all-content h4 {
  font-size: 30px;
  color: #000000;
  font-weight: 500;
  line-height: 44px;
  margin-bottom: 27px;
}
.about-all-content p {
  font-size: 15px;
  font-weight: 400;
  color: #8e9caa;
  line-height: 36px;
  margin: 0;
  padding-right: 9px;
}

.faq-wrapper .card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  margin-bottom: 15px;
}
.faq-wrapper .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid transparent;
}

.faq-wrapper .btn-link {
  color: #000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 55px;
  background: #f6f6f6;
  padding: 0 30px;
  font-size: 18px;
  font-weight: 500;
}

.about-all-content {
  margin-bottom: 59px;
}
.faq-wrapper .card-header button[aria-expanded="true"] i {
  transform: rotate(91deg);
  transition: 0.3s;
}

.faq-wrapper .card-header button[aria-expanded="false"] i {
  transform: rotate(0deg);
  transition: 0.3s;
}
.faq-wrapper .card-header button[aria-expanded="true"] {
  color: #fff;
  background: #0691e2;
  background: -moz-linear-gradient(45deg, #0691e2 0%, #016df2 100%);
  background: -webkit-linear-gradient(45deg, #0691e2 0%, #016df2 100%);
  background: linear-gradient(45deg, #0691e2 0%, #016df2 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0691e2', endColorstr='#016df2',GradientType=1 );
}

.faq-wrapper .btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0.25);
}

.faq-wrapper .card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 20px;
  color: #4b4b4b;
  font-weight: 400;
  line-height: 30px;
  font-size: 15px;
  margin: 0;
}
.header-mainmenu i {
  display: none;
}
/* About area css End */

.close-icon i {
  position: absolute;
  top: 22px;
  right: 15px;
  font-size: 28px;
  color: #222;
  cursor: pointer;
}

.footer-social {
  text-align: right;
}

/* Rules area css Start */

a[role="tab"] {
  font-family: "Raleway", sans-serif;
}

.rulse-area {
  padding-bottom: 80px !important;
}
.vertical-wrap-text h4 {
  font-size: 30px;
  color: #000;
  font-weight: 500;
  margin-bottom: 20px;
}
.rulse-select .nice-select {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #707070;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 18px;
  font-weight: 400;
  height: 55px;
  line-height: 55px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  color: #000;
  margin-bottom: 77px;
}

.nice-select::after {
  border-bottom: 1px solid #797979;
  border-right: 1px solid #797979;
  content: "";
  display: block;
  height: 8px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  width: 8px;
}
.rulse-select .nice-select.open .list {
  pointer-events: auto;
  width: 100%;
}

.vertical-pills .nav-link {
  display: block;
  width: 223px;
  height: 55px;
  line-height: 41px;
  text-align: center;
  background: #f6f6f6;
  margin-bottom: 11px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
}

.vertical-pills .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background: #0587e6;
  background: -moz-linear-gradient(top, #0587e6 1%, #006bf2 99%, #006bf2 99%);
  background: -webkit-linear-gradient(
    top,
    #0587e6 1%,
    #006bf2 99%,
    #006bf2 99%
  );
  background: linear-gradient(to bottom, #0587e6 1%, #006bf2 99%, #006bf2 99%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0587e6', endColorstr='#006bf2',GradientType=0 );
}
.vertical-wrap-text p {
  font-size: 15px;
  color: #000000;
  font-weight: 400;
  line-height: 36px;
  padding-right: 147px;
}
/* Rules area css End */

/* off-canvas css Start */

.off-canvas-menu-wrap {
  width: 300px;
  height: 100%;
  background: #fff;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.3s;
  transform: translateX(-300px);
}

.off-canvas-menu-wrap.active {
  transform: translateX(0);
}

.off-canvas-menu {
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

.off-canvas-menu li {
  list-style: none;
  padding: 0;
}

.off-canvas-menu li a {
  font-size: 16px;
  color: #333;
  position: relative;
  transition: 0.3s;
  font-weight: 500;
  display: block;
  padding: 12px 0;
  border-bottom: 1px solid#ddd;
  font-family: "Roboto", sans-serif;
}
.cls-off-canvas-menu {
  position: absolute;
  top: 46px;
  right: 25px;
  font-size: 28px;
  color: #222;
  cursor: pointer;
}

.toggle-bar i {
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}

.toggle-bar {
  display: none;
}

.off-canvas-overlay {
  background: #000;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 9;
  visibility: hidden;
  transition: 0.3s;
  cursor: pointer;
}

.off-canvas-overlay.active {
  opacity: 0.5;
  visibility: visible;
}

.toggle-bar {
  display: none;
}

/* off-canvas End */

@media (max-width: 768px) {
  .header-logo {
    margin: 0;
  }

  .trans-1 {
    display: none;
  }

  .Sticky {
    padding: 15px !important;
  }

  .Sticky .header-mainmenu ul li a {
    padding: 12px 30px;
  }

  .Sticky .right-btn {
    margin-top: -5px;
  }

  .Sticky .trans-1 {
    display: none;
  }
  /* allow resizing on mobile, work on better fix later */
}
